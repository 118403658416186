.contact_container {
  display: flex;
  margin: 1rem auto;
  justify-content: space-around;
  gap: 20px;
}

.left_box {
  width: 50%;
  padding: 4rem;
}

.contact_details {
  padding: 2rem 0 0 2rem;
}

p {
  font-size: var(--fs-medium);
}

.email{
  text-decoration: none;
  cursor:pointer;
  font-size: 2rem;
}

h3 {
  margin-top: 1rem;
  font-size: var(--fs-big);
}

.right_box {
  flex-grow: 1;
  padding: 1rem;
}

.form_container {
  margin: 3rem auto;
}

.form_wrapper {
  margin: 0.5rem;
}

.caption {
  font-size: var(--fs-medium);
}
.required {
  margin-left: 2px;
}

.caption_text {
  width: 100%;
  max-width: 300px;
  padding: 0.5rem;
  margin: 4px 0;
  border: 1px solid var(--gray100);
  border-radius: 4px;
}

.caption_text:focus {
  outline: 2px solid var(--primary);
}

.button {
  font-size: var(--fs-medium);
  padding: 0.8rem 2rem;
  margin: 1rem 1rem;
  background-color: var(--primary);
  cursor: pointer;
  border: none;
  border-radius: var(--radius);
}

@media screen and (max-width: 768px) {
  .contact_container {
    flex-direction: column;
    margin: 2rem auto;
  }
  .form_container {
    margin: 1rem auto;
  }

  .left_box {
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid var(--gray100);
  }
  .right_box {
    padding: 1rem;
  }

  .contact_details {
    padding-left: 0;
  }

  .form_container {
    margin: 1rem auto;
  }

  .button {
    margin: 0.5rem 1rem;
  }
}
