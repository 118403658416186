.about_container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 4rem 10rem;
}

.col-1 {
  flex-shrink: 0;
  flex-basis: 60%;
}

.details {
  font-size: var(--fs-medium);
  margin: 1rem;
}

.pic {
  border-radius: 0px 0px 20px;
  width: 20rem !important;
  height: 55vh;
  
}

@media screen and (max-width: 768px) {
  .about_container {
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  }
  .pic{
    border-radius: 0px ;
  }
}
