footer {
  border-top: 1px solid var(--gray100);
  text-align: center;
  margin-bottom: 1rem;
}

.f_social_icons {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

.f_social_icons a {
  font-size: var(--fs-medium);
  margin: 0 1rem;
}
