/* colors */
:root {
  --primary: hsl(187, 90%, 44%);
  --primary-light: hsl(187, 90%, 91%);
  --primary-dark: hsl(187, 89%, 21%);
  --white: hsl(0, 0%, 99%);
  --black: hsl(0, 0%, 10%);
  --gray100: hsl(0, 0%, 78%);
  --gray300: hsl(0, 0%, 57%);
  --gray500: hsl(0, 0%, 37%);
  --gray700: hsl(0, 0%, 19%);
  --success: hsl(120, 100%, 25%);
  --error: hsl(0, 100%, 50%);

  --font-family: "sans-serif";

  --fw-light: 200;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-bold: 700;

  --fs-xsmall: 0.875rem; /* 14px */
  --fs-small: 1rem; /* 16px */
  --fs-medium: 1.2rem; /* 20px */
  --fs-big: 1.5rem; /* 24px */
  --fs-xbig: 1.75rem; /* 28px */

  --radius: 0.5rem;
}

/* Box Sizing rules */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margins */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults  */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: var(--white);
}

/* Make images easier to work with */
img,
picture,
svg {
  object-fit: cover;
  display: block;
}

/* anchor tags styling */
ul,
li,
a {
  list-style: none;
  text-decoration: none;
  color: var(--gray700);
}

/* General Styling */
body {
  font-family: var(--font-family);
  font-size: var(--fs-small);
}
